import * as React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LOGO from "../../img/logo2.png";
import {Button} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {useNavigate} from "react-router-dom";
import {COLORS as Color} from "../Constant/Color";
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const navItems = [
    {name: 'Home', nav: '/home'},
    {name: 'About', nav: '/about'},
    {name: 'Products', nav: '/sectors'},
    // {name: 'Investor Relations', nav: '/investorRelations'},
    {name: 'Gallary', nav: '/career'},
    // {name: 'News Rooms', nav: '/news'},
    {name: 'Contacts', nav: '/contact'},]

const Header = () => {
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>

            <Typography
                style={{backgroundImage: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))',}}
                variant="h6" sx={{my: 2}}>
                <img src={LOGO} style={{width: '80px'}} alt={'logo'}/>
            </Typography>
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.name}>
                        <ListItemButton sx={{textAlign: 'center'}}>
                            <ListItemText style={{color: Color.white}} primary={item.name} onClick={() => {
                                window.scrollTo(0, 0);
                                navigate(item.nav)
                            }}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{
            display: "flex",
            backgroundImage: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))',
        }}>
            <AppBar component="nav" style={{
                backgroundImage: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))',
                boxShadow: '0px 0px 50px #000000'
            }}>
                <Toolbar style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundImage: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))',
                }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <MenuIcon style={{color: Color.white}}/>
                    </IconButton>
                    <img src={LOGO} style={{width: '80px'}} alt={'logo'}/>
                    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                        {navItems.map((item) => (
                            <Button style={{color: Color.white, fontWeight: 'bolder'}} key={item.name}
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        navigate(item.nav)
                                    }} children={item.name}/>
                        ))}
                    </Box>
                    <Box sx={{display: {xs: 'flex', md: 'flex'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={() => {
                                window.scrollTo(0, 0);
                                navigate('/')
                            }}
                            color="inherit"
                        >
                            <AccountCircleOutlinedIcon/>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={()=>{navigate('/news')}}
                            color="inherit"
                        >
                            <ShoppingBagOutlinedIcon/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav"
                 style={{backgroundImage: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))',}}>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: 240,
                            backgroundImage: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))',
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

export default Header;
