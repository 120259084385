import * as React from 'react';
import {Button, Card, CardActionArea, CardContent, CardMedia, Grid} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import MainSlide from "../img/home/Main Slide.png";
import product from "../img/home/product.png";

const Products = () => {
    const products = [
        {
            url: product,
            title: 'Ayurveda Tea',
            description: 'The power of Ayurveda in a spicy tea with healing properties of turmeric',
            price: 'Rs.500',
            width: '30%',
        },
        {
            url: product,
            title: 'Ayurveda Tea',
            description: 'The power of Ayurveda in a spicy tea with healing properties of turmeric',
            price: 'Rs.500',
            width: '30%',
        },
        {
            url: product,
            title: 'Ayurveda Tea',
            description: 'The power of Ayurveda in a spicy tea with healing properties of turmeric',
            price: 'Rs.500',
            width: '30%',
        },
        {
            url: product,
            title: 'Ayurveda Tea',
            description: 'The power of Ayurveda in a spicy tea with healing properties of turmeric',
            price: 'Rs.500',
            width: '30%',
        },
        {
            url: product,
            title: 'Ayurveda Tea',
            description: 'The power of Ayurveda in a spicy tea with healing properties of turmeric',
            price: 'Rs.500',
            width: '30%',
        },
        {
            url: product,
            title: 'Ayurveda Tea',
            description: 'The power of Ayurveda in a spicy tea with healing properties of turmeric',
            price: 'Rs.500',
            width: '30%',
        },
        {
            url: product,
            title: 'Ayurveda Tea',
            description: 'The power of Ayurveda in a spicy tea with healing properties of turmeric',
            price: 'Rs.500',
            width: '30%',
        },
        {
            url: product,
            title: 'Ayurveda Tea',
            description: 'The power of Ayurveda in a spicy tea with healing properties of turmeric',
            price: 'Rs.500',
            width: '30%',
        },
        {
            url: product,
            title: 'Ayurveda Tea',
            description: 'The power of Ayurveda in a spicy tea with healing properties of turmeric',
            price: 'Rs.500',
            width: '30%',
        },
    ];
    const navigate = useNavigate();
    return (
        <div style={{
            backgroundColor: 'white', fontSize: ' calc(10px + 2vmin)',
            color: 'white', marginTop: '80px'
        }}>
            <img style={{width: "100%", height: '500px', objectFit: 'cover'}}
                 src={MainSlide} alt=""/>
            {/*start products*/}
            <Grid>
                <Grid item
                      justifyContent="center"
                      alignItems="center"
                      xs={6}
                      style={{overflow: "hidden"}}>
                        <h3 style={{color: 'black'}}>"Empowering Healthy Living, Sustaining a Better Future"</h3>
                        <h2 style={{color: '#01C38D'}}>OUR PRODUCTS</h2>
                        <Grid style={{alignItem: 'center'}} container spacing={1}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={3}
                                style={{paddingLeft: '10%', paddingRight: '10%', paddingBottom: 50}}
                            >
                                {products.map((newsDetail) => (
                                    <Grid key={newsDetail.url} item xs={12} sm={4} style={{marginBottom: 20}}>
                                        <Card style={{
                                            border: '10px solid',
                                            borderRadius: 10,
                                            borderImageSlice: 1,
                                            borderWidth: 10,
                                            borderImageSource: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))'
                                        }}>
                                            <CardActionArea onClick={() => {
                                                window.scrollTo(0, 0);
                                                navigate(`/sectors/${newsDetail.title}`)
                                            }}>
                                                <CardMedia
                                                    component="img"
                                                    height="140"
                                                    image={newsDetail.url}
                                                    alt="green iguana"
                                                    style={{height: 250}}
                                                />
                                                <CardContent>
                                                    <h2 style={{
                                                        color: '#191E29',
                                                        margin: 0,
                                                        fontFamily: 'Baskervville'
                                                    }}>
                                                        {newsDetail.title}
                                                    </h2>
                                                    <h2 style={{fontFamily: 'Baskervville'}}>
                                                        {newsDetail.description}
                                                    </h2>
                                                    <h1 style={{fontFamily: 'Roboto', color: '#191E29',}}>
                                                        {newsDetail.price}
                                                    </h1>
                                                    <Button style={{
                                                        fontFamily: 'Baskervville',
                                                        width: 160,
                                                        height: 50,
                                                        margin: 'auto',
                                                        marginBottom: 20,
                                                        borderRadius: '10px',
                                                        color: 'white',
                                                        backgroundImage: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))',
                                                    }} onClick={() => {
                                                        window.scrollTo(0, 0);
                                                        navigate('/about')
                                                    }}>ADD TO BAG</Button>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Products;
