import * as React from 'react';
import homeImage from "../img/news/slide.png";
import {Button, Grid} from "@mui/material";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {COLORS as Color} from "../Common/Constant/Color";
import LOGO from "../img/logo2.png";
import OutlinedInput from "@mui/material/OutlinedInput";

const Login = () => {
    return (
        <div style={{
            backgroundImage: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))',
            fontSize: ' calc(10px + 2vmin)',
            color: 'white', marginTop: '64px'
        }}>
            <Grid container justifyContent="center"
                  alignItems="center" className={'wrapper'} style={{margin: 'auto'}}>
                <Grid item xs={10} sm={6}  className={'box'}>
                    <img src={LOGO} style={{width: '25%'}} alt={'logo'}/>
                    <div style={{display: 'flex', flexDirection: "column"}}>
                        <OutlinedInput startAdornment={
                            <InputAdornment position="start">
                                <AccountCircleOutlinedIcon/>
                            </InputAdornment>
                        } style={{
                            textAlign: 'center',
                            backgroundColor: Color.white,
                            opacity: 0.5,
                            width: '100%',
                            borderRadius: 20,
                            margin: '15px 0'
                        }}
                                       placeholder="Username"/>
                        <OutlinedInput startAdornment={
                            <InputAdornment position="start">
                                <LockOutlinedIcon/>
                            </InputAdornment>
                        } style={{
                            textAlign: 'center',
                            backgroundColor: Color.white,
                            opacity: 0.5,
                            width: '100%',
                            borderRadius: 20,
                            margin: '15px 0'
                        }}
                                       placeholder="Password"/>
                        <div id="textbox">
                            <p style={{fontFamily:'Baskervville',float:'left'}}><u>Forgot Password?</u></p>
                            <p style={{fontFamily:'Baskervville',float:'right'}}><u>Create Account</u></p>
                        </div>
                        <Button style={{
                            fontFamily:'Baskervville',
                            width: 160,
                            height:50,
                            margin: 'auto',
                            borderRadius: '10px',
                            color: 'white',
                            backgroundImage: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))',
                        }}>Sign In</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Login;
