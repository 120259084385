import * as React from 'react';
import {Container, Grid,Button} from "@mui/material";
import product from "../img/products/product.png";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import {useParams} from "react-router-dom";

const Item = () => {
    let {name} = useParams();
    const [count, setCount] = React.useState(0);

    const handleIncrement = () => {
        setCount(count + 1);
    }

    const handleDecrement = () => {
        if (count !== 0) {
            setCount(count - 1);
        }
    }

    return (
        <div style={{
            backgroundColor: 'white', fontSize: ' calc(10px + 2vmin)',
            color: 'black', marginTop: '80px'
        }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                    <img style={{width: "80%"}} src={product} alt=""/>
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'center',}}>
                    <p style={{
                        textAlign: 'center',
                        color: '#1D6F3F',
                        fontStyle: 'SemiBold',
                    }}>{name}</p>
                    <p>The power of Ayurveda in a spicy tea with healing properties of turmeric</p>
                    <h2 style={{fontStyle: 'Bold'}}>
                        Rs.500</h2>
                    <Container style={{}}>
                        <RemoveCircleRoundedIcon style={{color: "#1D6F3F", marginRight: 20}} onClick={() => {
                            handleDecrement()
                        }}/>

                        {count}
                        <AddCircleRoundedIcon style={{color: "#1D6F3F", marginLeft: 20}} onClick={() => {
                            handleIncrement()
                        }}/>
                    </Container>

                    <Button style={{
                        fontFamily: 'Baskervville',
                        width: 160,
                        height: 50,
                        margin: 'auto',
                        marginTop:20,
                        marginBottom: 20,
                        borderRadius: '10px',
                        color: 'white',
                        backgroundImage: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))',
                    }} onClick={() => {
                        window.scrollTo(0, 0);
                        // navigate('/about')
                    }}>ADD TO BAG</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default Item;
