import {Container, Grid} from "@mui/material";
import * as React from "react";
import product from "../../img/products/product.png";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const CartItem = () => {
    const [count, setCount] = React.useState(0);

    const handleIncrement = () => {
        setCount(count + 1);
    }

    const handleDecrement = () => {
        if (count !== 0) {
            setCount(count - 1);
        }
    }
    return (
        <Grid container>
            <Grid item xs={2} style={{alignSelf: 'center'}}>
                <img style={{width: "100%"}} src={product} alt=""/>
            </Grid>
            <Grid item xs={3} style={{alignSelf: 'center'}}>
                <p style={{
                    textAlign: 'center',
                    color: '#1D6F3F',
                    fontStyle: 'SemiBold',
                    fontSize:'90%',margin:0
                }}>Ayurveda Tea</p>
                <p  style={{
                    fontSize:'80%',margin:0
                }}>100g Pouch | 40 Cups</p>
            </Grid>
            <Grid item xs={3} style={{alignSelf: 'center'}}>
                <Container>
                    <AddCircleRoundedIcon style={{color: "#191E29",fontSize:'100%',marginRight:'7%'}} onClick={() => {
                        handleIncrement()
                    }}/>
                    {count}
                    <RemoveCircleRoundedIcon style={{color: "#01C38D",fontSize:'100%',marginLeft:'7%'}} onClick={() => {
                        handleDecrement()
                    }}/>
                </Container>
            </Grid>
            <Grid item xs={2} style={{alignSelf: 'center'}}>
                <h2 style={{fontStyle: 'Bold',fontSize:'110%'}}>
                    Rs.500</h2>
            </Grid>
            <Grid item xs={1} style={{alignSelf: 'center'}}>
                <CancelRoundedIcon style={{color: "#FF0000",fontSize:'100%'}} onClick={() => {
                    console.log("remove item");
                }}/>
            </Grid>
        </Grid>
    )
}

export default CartItem;
