import * as React from 'react';
import background from "../img/home/background.jpg";
import background3 from "../img/home/back3.jpg";
import product from "../img/home/product.png";
import MainSlide from "../img/home/Main Slide.png";
import tea from "../img/home/tea.jpg";
import cosmetics from "../img/home/cosmetics.jpg";
import spices from "../img/home/spices.jpg";
import sweet from "../img/home/sweet.jpg";
import coconut from "../img/home/coconut.png";
import animalCare from "../img/home/animalCare.jpg";

import {Button, Grid, Typography, Card, CardActionArea, CardMedia, CardContent} from "@mui/material";
import {styled} from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import {COLORS as Color} from "../Common/Constant/Color";
import {useNavigate} from "react-router-dom";
import {AnimationOnScroll} from 'react-animation-on-scroll';

const Home = () => {
    const sectors = [
        {
            url: tea,
            title: 'Tea',
            width: '30%',
        },
        {
            url: cosmetics,
            title: 'Cosmetics',
            width: '30%',
        },
        {
            url: spices,
            title: 'Spices',
            width: '30%',
        },
        {
            url: sweet,
            title: 'Sweet',
            width: '30%',
        },
        {
            url: coconut,
            title: 'Coconut',
            width: '30%',
        },
        {
            url: animalCare,
            title: 'Animal Care',
            width: '30%',
        },
    ];
    const products = [
        {
            url: product,
            title: 'Ayurveda Tea',
            description: 'The power of Ayurveda in a spicy tea with healing properties of turmeric',
            price: 'Rs.500',
            width: '30%',
        },
        {
            url: product,
            title: 'Ayurveda Tea',
            description: 'The power of Ayurveda in a spicy tea with healing properties of turmeric',
            price: 'Rs.500',
            width: '30%',
        },
        {
            url: product,
            title: 'Ayurveda Tea',
            description: 'The power of Ayurveda in a spicy tea with healing properties of turmeric',
            price: 'Rs.500',
            width: '30%',
        },
    ];

    const ImageButton = styled(ButtonBase)(({theme}) => ({
        position: 'relative',
        height: 250,
        [theme.breakpoints.down('sm')]: {
            width: '100% !important', // Overrides inline-style
            height: 150,
        },
        '&:hover, &.Mui-focusVisible': {
            zIndex: 1,
            '& .MuiImageBackdrop-root': {
                opacity: 0.15,
            },
            '& .MuiImageMarked-root': {
                opacity: 0,
            },
            '& .MuiTypography-root': {
                border: '4px solid currentColor',
            },
        },
    }));

    const ImageSrc = styled('span')({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    });

    const Image = styled('span')(({theme}) => ({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    }));

    const ImageBackdrop = styled('span')(({theme}) => ({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    }));

    const ImageMarked = styled('span')(({theme}) => ({
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    }));
    const navigate = useNavigate();
    return (
        <div style={{
            backgroundColor: Color.white, fontSize: ' calc(10px + 2vmin)',
            color: 'white', marginTop: '80px'
        }}>
            <div>

                <img style={{width: "100%", height: '500px', objectFit: 'cover'}}
                     src={MainSlide} alt=""/>
            </div>
            {/*start about*/}
            <Grid container
                  direction="row"
                  justifyContent="right"
                  alignItems="center" spacing={1} style={{
                marginTop: "20px",
                background: `url(${background}) no-repeat center center`,
                backgroundSize: 'cover',
            }}>
                <Grid item xs={12} sm={6}>
                    <div style={{textAlign: 'center'}}>

                        <h3 style={{color: Color.black}}>WELCOME TO</h3>

                        <h2 style={{color: '#01C38D'}}>IMROOKA</h2>
                    </div>
                    <p style={{textAlign: 'center', color: Color.black, paddingLeft: 20, paddingRight: 20}}>
                        We are dedicated to empowering healthy and eco-friendly living! As a diversified
                        organization, we offer a variety of services, including organic agricultural products,
                        ayurvedic medicinal products and treatments, medical services, and cosmetics.

                    </p>
                    <p style={{textAlign: 'center', color: Color.black, paddingLeft: 20, paddingRight: 20}}>
                        We also have a non-profit organization committed to improving the welfare of children in
                        need.</p>
                    <p style={{textAlign: 'center', color: Color.black, paddingLeft: 20, paddingRight: 20}}>
                        With our modern IT and marketing solutions, we strive to make a positive impact on the
                        world. Our mission is to inspire individuals and communities to live sustainably and thrive.
                        We invite you to explore our website to learn more about our services and how we can help
                        you achieve</p>
                    <Button style={{
                        fontFamily: 'Baskervville',
                        width: 160,
                        height: 50,
                        margin: 'auto',
                        marginBottom: 20,
                        borderRadius: '10px',
                        color: 'white',
                        backgroundImage: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))',
                    }} onClick={() => {
                        window.scrollTo(0, 0);
                        navigate('/about')
                    }}>Explore Now</Button>
                </Grid>
            </Grid>
            {/*end About*/}

            {/*start collection*/}
            <Grid style={{
                background: `url(${background3}) no-repeat center center`,
                backgroundSize: 'cover', paddingBottom: '20px', marginTop: '50px',
            }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={5}
                    style={{overflow: "hidden"}}
                >
                    <Grid item>
                        <h3 style={{color: 'black'}}>Discover the Benefits of Our Diversified Services</h3>
                        <h2 style={{color: '#01C38D'}}>OUR COLLECTION</h2>
                    </Grid>
                    <Grid sx={{
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        paddingLeft: 6
                    }} container spacing={3}>
                        {sectors.map((image) => (
                            <Grid item xs={12} sm={4}>
                                <ImageButton
                                    focusRipple
                                    key={image.title}
                                    style={{
                                        width: '100%',
                                        border: '10px solid',
                                        borderImageSlice: 1,
                                        borderWidth: 10,
                                        borderImageSource: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))'
                                    }}
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        // navigate(`/sectors/${image.title}`)
                                    }}
                                >
                                    <ImageSrc style={{
                                        backgroundImage: `url(${image.url})`,
                                    }}/>
                                    <ImageBackdrop className="MuiImageBackdrop-root"/>
                                    <Image>
                                        <Typography
                                            component="span"
                                            variant="subtitle1"
                                            color="inherit"
                                            sx={{
                                                position: 'relative',
                                                p: 4,
                                                pt: 2,
                                                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                            }}
                                        >
                                            {image.title}
                                            <ImageMarked className="MuiImageMarked-root"/>
                                        </Typography>
                                    </Image>
                                </ImageButton>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            {/*End Collection*/}

            {/*start products*/}
            <Grid>
                <Grid item
                      justifyContent="center"
                      alignItems="center"
                      xs={6}
                      style={{overflow: "hidden"}}>
                    <AnimationOnScroll animateIn="animate__fadeInLeft">
                        <h3 style={{color: 'black'}}>"Empowering Healthy Living, Sustaining a Better Future"</h3>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeInRight">
                        <h2 style={{color: '#01C38D'}}>OUR PRODUCTS</h2>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <Grid style={{alignItem: 'center'}} container spacing={1}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={3}
                                style={{paddingLeft: '10%', paddingRight: '10%', paddingBottom: 50}}
                            >
                                {products.map((newsDetail) => (
                                    <Grid key={newsDetail.url} item xs={12} sm={4} style={{marginBottom: 20}}>
                                        <Card style={{
                                            border: '10px solid',
                                            borderRadius: 10,
                                            borderImageSlice: 1,
                                            borderWidth: 10,
                                            borderImageSource: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))'
                                        }}>
                                            <CardActionArea  onClick={() => {
                                                window.scrollTo(0, 0);
                                                navigate(`/sectors/${newsDetail.title}`)
                                            }}>
                                                <CardMedia
                                                    component="img"
                                                    height="140"
                                                    image={newsDetail.url}
                                                    alt="green iguana"
                                                    style={{height: 250}}
                                                />
                                                <CardContent>
                                                    <h2 style={{
                                                        color: '#191E29',
                                                        margin: 0,
                                                        fontFamily: 'Baskervville'
                                                    }}>
                                                        {newsDetail.title}
                                                    </h2>
                                                    <h2 style={{fontFamily: 'Baskervville'}}>
                                                        {newsDetail.description}
                                                    </h2>
                                                    <h1 style={{fontFamily: 'Roboto', color: '#191E29',}}>
                                                        {newsDetail.price}
                                                    </h1>
                                                    <Button style={{
                                                        fontFamily: 'Baskervville',
                                                        width: 160,
                                                        height: 50,
                                                        margin: 'auto',
                                                        marginBottom: 20,
                                                        borderRadius: '10px',
                                                        color: 'white',
                                                        backgroundImage: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))',
                                                    }} onClick={() => {
                                                        window.scrollTo(0, 0);
                                                        navigate('/about')
                                                    }}>ADD TO BAG</Button>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </AnimationOnScroll>
                </Grid>
            </Grid>
            {/*End Products*/}
        </div>
    )
}


export default Home;
