import './App.css';
import {Route, Routes, HashRouter} from 'react-router-dom';
import Header from "./Common/Layout/Header";
import Footer from "./Common/Layout/Footer";
import About from "./View/about";
import Products from "./View/products";
import InvestorRelations from "./View/investorRelations";
import Contacts from "./View/contacts";
import News from "./View/news";

import Item from "./View/item";
import Career from "./View/career";
import UnderConstruction from "./View/underConstruction";
import Login from "./View/login";
import Home from "./View/home";

function App() {
  return (
      <div className="App">
        <HashRouter>
          <Header/>
          <Routes>
            <Route path={"/"} element={<Login/>}/>
            <Route path={"/home"} element={<Home/>}/>
            <Route exact path={"/about"} element={<About/>}/>
            <Route path={"/sectors"} element={<Products/>}/>
            <Route path={"/career"} element={<Career/>}/>
            <Route exact path="/sectors/:name" element={<Item/>}/>
            <Route path={"/investorRelations"} element={<InvestorRelations/>}/>
            <Route path={"/news"} element={<News/>}/>
            <Route exact path={"/contact"} element={<Contacts/>}/>
            <Route path={"/underConstruction"} element={<UnderConstruction/>}/>
          </Routes>
          <Footer/>
        </HashRouter>
      </div>
  );
}

export default App;

