import * as React from 'react';
import {Button, Grid} from "@mui/material";
import CartItem from "../Common/Components/CartItem";
import {COLORS as Color} from "../Common/Constant/Color";
import OutlinedInput from "@mui/material/OutlinedInput";
import PhoneInput from "react-phone-input-2";

const News = () => {
    return (
        <div style={{
            backgroundColor: 'white', fontSize: ' calc(10px + 2vmin)',
            color: 'black', marginTop: '80px', marginBottom: '30px'
        }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <h4>Your Bag</h4>
                    <CartItem/>
                    <CartItem/>
                    <hr/>
                    <Grid container spacing={1}>
                        <Grid item xs={7} style={{alignSelf: 'center'}}>
                            TOTAL
                        </Grid>
                        <Grid item xs={4} style={{alignSelf: 'center'}}>
                            Rs.1000
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} style={{textAlign: 'center', width: '80%'}}>
                    <h4>Information</h4>
                    <div style={{
                        width: '95%', margin: "auto",padding:20,
                        border: '10px solid',
                        borderRadius: 10,
                        borderImageSlice: 1,
                        borderWidth: 5,
                        borderImageSource: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))'
                    }}>
                        <OutlinedInput style={{
                            backgroundColor: Color.light,
                            width: '100%',
                            borderRadius: 10,
                            margin: '5px 0'
                        }}
                                       placeholder="Country/ Region"/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <OutlinedInput style={{
                                    backgroundColor: Color.light,
                                    width: '100%',
                                    borderRadius: 10,
                                    margin: '5px 0'
                                }}
                                               placeholder="First name"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <OutlinedInput item xs={12} sm={6} style={{
                                    backgroundColor: Color.light,
                                    width: '100%',
                                    borderRadius: 10,
                                    margin: '5px 0'
                                }}
                                               placeholder="Last Name"/>
                            </Grid>
                        </Grid>

                        <OutlinedInput style={{
                            backgroundColor: Color.light,
                            width: '100%',
                            borderRadius: 10,
                            margin: '5px 0'
                        }}
                                       placeholder="Address"/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <OutlinedInput style={{
                                    backgroundColor: Color.light,
                                    width: '100%',
                                    borderRadius: 10,
                                    margin: '5px 0'
                                }}
                                               placeholder="City"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <OutlinedInput item xs={12} sm={6} style={{
                                    backgroundColor: Color.light,
                                    width: '100%',
                                    borderRadius: 10,
                                    margin: '5px 0'
                                }}
                                               placeholder="Postal Code"/>
                            </Grid>
                        </Grid>

                        <PhoneInput style={{
                            backgroundColor: Color.light,
                            width: '100%',
                            height: 50,
                            borderRadius: 10,
                            margin: '5px 0'
                        }}
                                    country={'lk'}

                        />
                        <Button style={{
                            fontFamily: 'Baskervville',
                            width: 245,
                            height: 50,
                            margin: 'auto',
                            marginTop: 20,
                            marginBottom: 5,
                            borderRadius: '10px',
                            color: 'white',
                            backgroundImage: 'linear-gradient(to right,rgba(25, 30, 41, 1), rgba(19, 45, 70, 1), rgba(1, 195, 141, 1))',
                        }} onClick={() => {
                            console.log("click continue payment");
                        }}
                        >Continue to payment</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default News;
